import React from 'react'
import PropTypes from 'prop-types'
import _ from '../../src/lodash'
import { setFallbackImage } from '../../src/actions/setFallbackImage'

class NostoProductsListModal extends React.Component {
  constructor(props) {
    super(props)

    this.isAlreadyInCart = this.isAlreadyInCart.bind(this)
  }

  onErrorImageMissing(event) {
    setFallbackImage(event, this.props.fallbackImageSrc)
  }

  isAlreadyInCart(product) {
    const alreadyInCartProductIds = this.props.alreadyInCartProducts.map((item) => {
      if (item.course !== undefined) {
        return item.course.course_id
      } else {
        return item.product.product_id
      }
    })

    return alreadyInCartProductIds.includes(product.product_id)
  }

  nostoAdd(productId, nostoSlotId) {
    if (nostoSlotId !== undefined) {
      nostojs(api => {
        api.recommendedProductAddedToCart(productId.toString(), nostoSlotId);
      })
    }
  }

  renderBuyOrInCart(product, nostoSlotId) {
    if (this.isAlreadyInCart(product)) {
      return (
        <div className="CartProductItem-recommendedItemInWishlist">
          In Cart
        </div>
      )
    } else {
      return (
        <div className="CartGiftItemModal-item-buttons">
          <form action="orders/add_to_cart" method="post" onSubmit={this.nostoAdd(product.product_id, nostoSlotId)}>
            <input type="hidden" name="id" value={product.product_id} />
            <button type="submit" className="CartGiftItemModal-item-buttons-add" title="Add product to cart">Add to Cart</button>
          </form>
        </div>
      )
    }
  }

  productItem(product, productUrl, nostoSlotId) {
    return(
      <div className="CartGiftItemModal-item" key={product.product_id}>
        <a href={productUrl} className="CartGiftItemModal-item-img">
          <img src={product.image_url || this.props.fallbackImageSrc} alt={product.image_description} onError={(event) => this.onErrorImageMissing(event)} />
        </a>

        <div className="CartGiftItemModal-item-info">
          <a className="CartGiftItemModal-item-info--name" href={productUrl}>
            {product.name}
          </a>
          <a href={product.manufacturer.link_to} className="CartGiftItemModal-item-info--manufacturer">
            {product.manufacturer.name}
          </a>
          <a href={product.category.link_to} className="CartGiftItemModal-item-info--category">
            {product.category.name}
          </a>
        </div>

        <div className="CartGiftItemModal-item-price">
          <div className="CartGiftItemModal-item-price">
            {product.sell_price}
          </div>
        </div>

        <div className="CartGiftItemModal-item-buttons">
          {this.renderBuyOrInCart(product, nostoSlotId)}
        </div>
      </div>
    )
  }

  render() {
    if (!this.props.isModalOpen) {
      return null
    }
    const recommendedItems = _.isEmpty(this.props.recommendedProducts) ? this.props.fallbackProducts : this.props.recommendedProducts

    return (
      <div className="CartGiftItemModal">
        <div className="CartGiftItemModal-content">
          <div className="CartGiftItemModal-content-header">
            <div className="CartGiftItemModal-content-header-title">
              Recommended Products For You
            </div>
            <div className="CartGiftItemModal-content-header-cross" onClick={e => { this.props.onCloseClick(e) }}></div>
          </div>
          <div className="CartGiftItemModal-giftsList">
            {
              recommendedItems.map(item => {
                const product = item.product ? item.product : item
                const nostoSlotId = item.slot_id
                const productUrl = ((item.product_url !== undefined) ? item.product_url : item.link_to)

                return this.productItem(product, productUrl, nostoSlotId)
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

NostoProductsListModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  fallbackImageSrc: PropTypes.string.isRequired,
  recommendedProducts: PropTypes.array,
  fallbackProducts: PropTypes.array.isRequired
}

export default NostoProductsListModal
