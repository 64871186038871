import React from 'react'
import PropTypes from "prop-types"
import csrfToken from '../src/csrf_token'

class AddToDownloads extends React.Component {
    handle_click = (event) => {
        event.preventDefault()

        this.process_server()

    };

    process_server = () => {
        $.ajax({
            url: `/users/get_free?id=${this.props.item_id}`,
            dataType: 'json',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                this.process_response(data)

            }.bind(this),
            error: function(xhr) {
                if (xhr.status === 401) {
                    Mess.publish('show_error', 'You have to log in first')
                }

            }.bind(this)
        });
    };

    process_response = (data) => {
        if (data.error) {
            Mess.publish('show_error', data.error)
        }

        if (data.not_logged_in) {
            Mess.publish('log_in', this.process_server_again)
        }

        if (data.redirect) {
            window.location = data.redirect
        }

        if (data.open) {
            window.open(data.open, '', 'fullscreen=yes')
        }
    };

    process_server_again = () => {
        this.process_server()
    };

    render() {
        return <a href="#add_to_downloads" className="addtodownloads" title="Add to my downloads" data-layer={ this.props.data_layer } onClick={ this.handle_click }>add to my downloads</a>
    }
}

AddToDownloads.propTypes = {
    item_id: PropTypes.string,
    data_layer: PropTypes.object
}

export default AddToDownloads
