import React from 'react'
import ProcessLine from './ProcessLine'
import csrfToken from '../src/csrf_token'

class Pager extends React.Component {
    state = {
        current: this.props.pages.current,
        progress: false
    };

    timer = null;

    componentDidMount() {
        $(document).on('scroll', this.loadNext)
    }

    loadNext = () => {
        this.onBottom()


        window.clearTimeout(this.timer)

        if (!this.state.progress && this.props.pages.pages_count > this.state.current) {
            this.timer = window.setTimeout(this.loadOnProducts, 50)
        }
    };

    onBottom = () => {
        var $container = $(this.props.container)

        var view_offset = $container.position().top,
            view_height = $container.height(),
            scroll = $container.scrollTop(),
            top = scroll - view_offset,
            window_height = $container.height(),
            offset = (view_height - view_offset - scroll - window_height)

        return offset < 0
    };

    loadOnProducts = () => {

        if (this.onBottom()) {
            this.setState({ progress: true })

            var page = this.state.current + 1

            $.ajax({
                url: window.location.href,
                data: { page: page },
                headers: {
                  "X-CSRF-Token": csrfToken()
                },
                success: function(data) {
                    $(this.props.container).append(data)
                    ReactRailsUJS.mountComponents()
                    this.setState({ progress: false, current: page})

                }.bind(this),
                error: function(xhr, status, err) {
                    console.error(this.props.url, status, err.toString());
                    this.setState({ progress: false })
                }.bind(this)
            });
        }
    };

    render() {
        return <ProcessLine progress={ this.state.progress } />
    }
}

export default Pager
