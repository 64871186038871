import React from 'react'
import PropTypes from 'prop-types'
import HeaderCartProductItem from './HeaderCartProductItem'
import '../src/dtl'
import { mediaDesktop } from 'constants/mediaQueries'
import csrfToken from '../src/csrf_token'

class HeaderCart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      headerCartVisible: false,
      cart: {
        cartItems: [],
        couponValue: null,
        fallbackImageSrc: null,
        selectedGift: null,
        totalWithVat: null,
      }
    }

    this.toggleExpandItems = this.toggleExpandItems.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.refreshCart = this.refreshCart.bind(this)
    this.requestCartData = this.requestCartData.bind(this)
  }

  componentDidMount() {
    Mess.subscribe('refresh_cart', this.refreshCart)
    Mess.subscribe('refresh_cart', this.updateDotdigitalCart)
    Mess.subscribe('refresh_wishlist', this.refreshCart)

    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside(event) {
    if (this.headerCartWrapperRef && !this.headerCartWrapperRef.contains(event.target)) {
      this.setState({
        headerCartVisible: false
      })
    }
  }

  refreshCart(data) {
    if (!data.cart.rto) {
      this.setState({
        cart: data.cart
      })
    }
  }

  updateDotdigitalCart() {

    if (!window.dmPt) return;

    $.ajax({
      url: '/api/v1/carts/dotdigital_cart',
      dataType: 'json',
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      success: function(data) {
        window.dmPt('cartInsight', data);
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(xhr, status, err.toString());
      }.bind(this)
    })
  }

  cartLink() {
    const cartItemsCount = this.state.cart.totalWithVat ? this.state.cart.cartItems.length : this.props.cartItemsCount

    const cartItemsPresent = (this.state.cart && (cartItemsCount > 0))

    if (cartItemsPresent) {
      return (
        <a onClick={this.toggleExpandItems} href="/cart" title={ `Cart: ${cartItemsCount}` }>Cart<em>{ cartItemsCount }</em></a>
      )
    } else {
      return (
        <a onClick={this.toggleExpandItems} href="/cart" title="Cart: empty" data-layer={ dtl('header/user menu/empty cart') } className="--empty">Cart</a>
      )
    }
  }

  requestCartData() {
    $.ajax({
      url: '/orders/cart',
      dataType: 'json',
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      success: function(data) {
        window.dmPt('cartInsight', data)

        this.setState({
          headerCartVisible: true,
          cart: data.cart
        })
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(xhr, status, err.toString())
      }.bind(this)
    })
  }

  toggleExpandItems(e) {
    if (mediaDesktop().matches) {
      e.preventDefault()

      if (this.state.headerCartVisible == false && this.state.cart.totalWithVat == null) {
        this.requestCartData()
      } else {
        this.setState({
          headerCartVisible: !this.state.headerCartVisible
        })
      }
    }
  }

  render() {
    const expandItemsClasses = this.state.headerCartVisible ? "HeaderCart-expandItems" : "HeaderCart-expandItems--hidden"
    const vatValue = this.state.cart.vatValue

    return (
      <div ref={element => this.headerCartWrapperRef = element} className="HeaderCart">
        { this.cartLink() }
        { this.state.cart.totalWithVat &&
          (<div className={expandItemsClasses}>
            <div className="HeaderCart-itemsWrapper">
              {this.state.cart.cartItems.map((item, index) => {
                return (
                  <HeaderCartProductItem key={item.order_item_id}
                                        item={item}
                                        fallbackImageSrc={this.state.cart.fallbackImageSource}
                                        isGift={false} />
                )
              })}

              { (this.state.cart.selectedGift !== null) && (
                <HeaderCartProductItem key={this.state.cart.selectedGift.id}
                                      item={this.state.cart.selectedGift}
                                      fallbackImageSrc={this.state.cart.fallbackImageSource}
                                      isGift={true} />
              )}
            </div>
            <div className="HeaderCart-vcTokensSection">
              <div className="HeaderCart-tokens">
                <span className="HeaderCart-tokensText">Tokens You Will Earn</span><span className="HeaderCart-tokensValue">{this.state.cart.tokensWillEarn}</span>
              </div>
              <div className="HeaderCart-vc">
                <span className="HeaderCart-vcText">Virtual Cash You Will Earn</span><span className="HeaderCart-vcValue">{this.state.cart.virtualCash}</span>
              </div>

              { this.state.cart.couponValue && (
                <div className="HeaderCart-vc">
                  <span className="HeaderCart-vcText">Coupon Value</span><span className="HeaderCart-vcValue">{this.state.cart.couponValue}</span>
                </div>
              )}

              { vatValue && (
                <div className="HeaderCart-vc">
                  <span className="HeaderCart-vcText">TAX ({this.state.cart.vatRate}%)</span><span className="HeaderCart-vcValue">{vatValue}</span>
                </div>
              )}
            </div>
            <div className="HeaderCart-totalButtonSection">
              <div className="HeaderCart-total">
                <span className="HeaderCart-totalText">Total</span><span className="HeaderCart-totalValue">{this.state.cart.totalWithVat}</span>
              </div>
              <div className="HeaderCart-buttonSection">
                <a href="/cart" className="HeaderCart-button">Go To Cart</a>
              </div>
            </div>
          </div>)
        }
      </div>
    )
  }
}

HeaderCart.propTypes = {
  cartItemsCount: PropTypes.number.isRequired,
}

export default HeaderCart
