/* eslint no-console:0 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'

window.$ = require("jquery")
import {} from 'jquery-ujs'

var getCookie = function (name) {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length === 2) return parts.pop().split(';').shift();
  return false;
}

var loadPapParams = function() {
  var params = new URLSearchParams(window.location.search);
  var papParams = ['a_aid', 'a_bid', 'a_cid', 'chan'];
  for (var i = 1; i < 6; i++) { papParams.push('data' + parseInt(i)); }

  papParams.forEach(function(paramName) {
    if (params.get(paramName) && !getCookie(paramName))
      document.cookie = paramName + "=" + params.get(paramName) + "; path=/";
  });
}

window.addEventListener('load', function() {
  loadPapParams();
});

var componentRequireContext = require.context("components", true);
require.context("images", true);
var ReactRailsUJS = require("react_ujs");

ReactRailsUJS.useContext(componentRequireContext);
