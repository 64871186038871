import React from 'react'
import PropTypes from 'prop-types'
import { setFallbackImage } from '../../src/actions/setFallbackImage'
import NostoProductsListModal from './NostoProductsListModal'

class CartRecommendedItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isNostoProductsListOpen: false,
      isMoreInfoModalOpen: false
    }

    this.openNostoProductsList = this.openNostoProductsList.bind(this)
    this.closeNostoProductsList = this.closeNostoProductsList.bind(this)
  }

  openNostoProductsList(event) {
    event.preventDefault()

    this.setState({
      isNostoProductsListOpen: true
    })
  }

  closeNostoProductsList(event) {
    event.preventDefault()

    this.setState({
      isNostoProductsListOpen: false
    })
  }

  render() {
    return (
      <div className="CartProductItem-listItem CartProductItem-emptyItem">
        <div className="CartProductItem-productData CartProductItem-productRecommendedData">
          <div className="CartProductItem-productData--name CartRecommendedItem-title">
            Recommended products based on your basket
          </div>
          <div className="CartProductItem-productData--category">
            Add more to your order
          </div>
        </div>
        <div className="CartProductItem-columnClear CartProductItem-columnClearEmpty">
          <div onClick={e => this.openNostoProductsList(e)} className="CartProductItem-addGiftBtn">Browse products</div>
        </div>

        <NostoProductsListModal isModalOpen={this.state.isNostoProductsListOpen}
                                onCloseClick={this.closeNostoProductsList}
                                fallbackImageSrc={this.props.fallbackImageSrc}
                                alreadyInCartProducts={this.props.alreadyInCartProducts}
                                recommendedProducts={this.props.recommendedProducts}
                                fallbackProducts={this.props.fallbackProducts} />
      </div>
    )
  }
}

CartRecommendedItem.propTypes = {
  fallbackImageSrc: PropTypes.string.isRequired,
  alreadyInCartProducts: PropTypes.array,
  recommendedProducts: PropTypes.array,
  fallbackProducts: PropTypes.array.isRequired
}

export default CartRecommendedItem
