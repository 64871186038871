export const instrumentsData = [
  {
    name: 'DC Snares',
    manufacturer: 'Plugin Boutique',
    description: "The ultimate snare construction kit has arrived. Created by multi award-winning artist, Davide Carbone, DC Snares is designed to simplify your workflow and add serious impact to your productions."
  },
  {
    name: 'Bloom Vocal Edit',
    manufacturer: 'Excite Audio',
    description: 'Bloom Vocal Edit fuses an extensive collection of energetic vocal chops, hooks, and one-shots into a versatile and dynamic instrument, meticulously crafted to inject your tracks with infectious vocal chops and hooks. This powerful tool transforms the way you incorporate vocals into your productions, making it effortless to create unique, professional-sounding chops that demand attention.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/64-Virtual-Instrument/12619-Bloom-Vocal-Edit'
  },
  {
    name: 'Bloom Vocal Aether',
    manufacturer: 'Excite Audio',
    description: "No more searching through vocal samples, with Bloom: Vocal Aether, you can switch between phrases to create unique hooks, build huge FX modulation to pull your listeners in and transform it all with a chain of vocal-specific processors.",
    url: 'https://www.pluginboutique.com/products/11515-Bloom-Vocal-Aether'
  },
  {
    name: 'Bloom Drum Breaks',
    manufacturer: 'Excite Audio',
    description: "Bloom Drum Breaks fuses high-energy drum loops, fills and one-shots into a dynamic and playable instrument, made to infuse your tracks with rhythmic vitality and designed to provide for genres that thrive on rhythm.",
    url: 'https://www.pluginboutique.com/products/11893-Bloom-Drum-Breaks'
  },
  {
    name: 'Bloom Bass Impulse',
    manufacturer: 'Excite Audio',
    description: "Bloom Bass Impulse merges an extensive array of modern synth bass loops, samples, and one-shots into a versatile and dynamic instrument, meticulously crafted to inject your tracks with low-end and tailored to fuel genres that demand contemporary bass sounds.",
    url: 'https://www.pluginboutique.com/products/11968-Bloom-Bass-Impulse'
  },
  {
    name: 'Lounge Lizard Session',
    manufacturer: 'Applied Acoustics Systems',
    description: "Lounge Lizard Session delivers authentic classic electric piano tones with outstanding sound quality, action, and feel.",
    url: 'https://www.pluginboutique.com/product/1-Instruments/4-Synth/1976-Lounge-Lizard-Session'
  },
  {
    name: 'Strum Session',
    manufacturer: 'Applied Acoustics Systems',
    description: "Combines an acoustic and an electric guitar in the same package. With chord recognition, chord voicing, strumming and picking action, MIDI riff library, amp and effects, playing guitar on a keyboard has never been so easy.",
    url: 'https://www.pluginboutique.com/product/1-Instruments/4-Synth/1978-Strum-Session'
  },
  {
    name: 'Hand Clap Studio',
    manufacturer: 'Robotic Bean',
    description: 'Add natural-sounding claps and snaps to your music with Hand Clap Studio. This instrument plugin is the ultimate tool for handclaps and finger snaps that fits your production perfectly.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/64-Virtual-Instrument/7345-Hand-Clap-Studio'
  },
  {
    name: 'WaveOSC',
    manufacturer: 'Plugin Boutique',
    description: 'WaveOSC puts the power of wavetable synthesis behind one single click. Featuring two standard oscillators and one wavetable oscillator, the third oscillator can instantly generate sonic wavetables from the output of oscillators 1 & 2, and features its own wavetable window where you can scan through wavetable sets to customise them.',
    url: 'https://www.pluginboutique.com/products/10929-WaveOSC'
  },
  {
    name: 'BigKick',
    manufacturer: 'Plugin Boutique',
    description: 'How many hours have you wasted searching through samples for the right kick? Do you want complete flexibility over the low end and attack of your kick? BigKick gets rid of the frustration and makes kick drum design easy and enjoyable.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/69-Generator/879-BigKick'
  },
  {
    name: 'CUBE Mini',
    manufacturer: 'Lunacy Audio',
    description: 'CUBE Mini is a game-changing sampler and vector synth plugin for immediate sonic inspiration. Mini unlocks 35 expertly-crafted sampled instruments, 120 hype-worthy presets, and a curated set of essential sound design tools, all perfectly suited for modern electronic, hip hop, pop, and genre-bending tracks.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/64-Virtual-Instrument/9065-CUBE-Mini'
  },
  {
    name: 'Ting',
    manufacturer: 'Klevgrand',
    description: 'Ting is an instrument providing 28 different percussive sounds originating from everyday t(h)ings laying around at home - such as silverware, a sofa, a toy drum, or a pair of car keys. Each sound is carefully multi-sampled and processed with the common goal to be musically playable.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/64-Virtual-Instrument/8734-Ting'
  },
  {
    name: 'Jussi',
    manufacturer: 'Klevgrand',
    description: 'Jussi emulates male voice vowels in a unique way. Thanks to a set of powerful parameters you can make Jussi sing anything from soft and airy choir-like vowels to hard and angry shouting. Formants are controlled by playing with different velocity, which makes it fun and easy to play using a MIDI keyboard.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/4-Synth/3361-Jussi-'
  },
  {
    name: 'Sektor',
    manufacturer: 'Initial Audio',
    description: 'Sektor is a polyphonic wavetable synth plugin with a highly intuitive user interface and a lot of customization features. Sektor was built to use a low amount of system resources while having a massive high-quality sound.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/4-Synth/4460-Sektor'
  },
  {
    name: 'Hattricks 1.5',
    manufacturer: 'Diginoiz',
    description: 'Hattricks 1.5 is a VST for trap hi-hats creation that makes the process smooth, easy and proficient. In technical terms, it is a sampler and a sequencer with rhythm divisions assigned to keys.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/64-Virtual-Instrument/7413-Hattricks-1-5'
  },
  {
    name: 'BLEASS Alpha Synthesizer',
    manufacturer: 'BLEASS',
    description: 'BLEASS Alpha Synthesizer is a virtual analogue polyphonic stereo synthesizer inspired by classic analogue synths and enhanced by the BLEASS’ very own musical and technical approach.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/4-Synth/8407-BLEASS-Alpha-Synthesizer'
  },
  {
    name: 'Playbeat 3',
    manufacturer: 'Audiomodern',
    description: 'Create infinite variations of the grooves while maintaining a sense of creativity, inspiration, and momentum. No two patterns will ever be the same.',
    url: 'https://www.pluginboutique.com/product/2-Effects/24-Sequencer/8352-Playbeat-3'
  },
  {
    name: 'Chordjam',
    manufacturer: 'Audiomodern',
    description: 'Chordjam is an innovative plugin that builds chords and progression patterns through intuitive user-guided randomization.',
    url: 'https://www.pluginboutique.com/product/2-Effects/24-Sequencer/7635-Chordjam'
  },
  {
    name: 'AcidBox V2',
    manufacturer: 'Audioblast',
    description: "The AcidBox V2 is a bass line synthesizer, a recreation of the iconic 303 bass line synthesizer from the 80's with a new interface, which makes it easier to create real-time sequences and tweak them in a live situation.",
    url: 'https://www.pluginboutique.com/product/1-Instruments/4-Synth/10477-AcidBox-V2'
  },
  {
    name: 'MininnDrum',
    manufacturer: 'Audio Blast',
    description: 'Audio Blast created three different sound ROMs with a selection of different sounds to get a global approach of the famous drum machine. The ROM 1 is a selection closer to the LM-1, the ROM 2 closer to the 9000 Version, and the ROM 3 is a custom adaptation of the 80’s drum sounds.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/7-Drum-Machine/10480-MininnDrum'
  },
  {
    name: 'Dandy',
    manufacturer: 'UJAM',
    description: "DANDY is your first-call when you need a seasoned professional bassist that is totally present in a mix without ever sounding “shouty”. There's no indie-rock attitude in the playing. No, DANDY quietly lays down ultra-smooth, highly-polished basslines without hogging the spotlight or taking up too much room in a mix. How civilized!",
    url: 'https://www.pluginboutique.com/product/1-Instruments/64-Virtual-Instrument/6618-DANDY'
  },
  {
    name: 'Deep',
    manufacturer: 'UJAM',
    description: "These beats run deep. If that's a quality you're looking to elicit in your music, Virtual Drummer is here to help. And we mean deep as in soulful, deep as in complex and intricate.",
    url: 'https://www.pluginboutique.com/product/1-Instruments/64-Virtual-Instrument/7235-DEEP'
  },
  {
    name: 'Midnite',
    manufacturer: 'Karanyi Sounds',
    description: 'Enter the world of Midnite, where granular textures meet intense overtones for an unparalleled musical experience and craft hybrid, heavy-granular, overdriven sounds. As a powerful multi-layer sound designer instrument with 5 studio-grade DSP effects, Midnite empowers you to shape your sounds like never before.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/4-Synth/11196-Midnite'
  },
  {
    name: '808 Studio 2',
    manufacturer: 'Initial Audio',
    description: '808 Studio 2 was designed for cutting edge quality 808 sub-bass. Perfect for hip hop and trap or any genre that requires a hard-hitting bass synth.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/4-Synth/6299-808-Studio-2'
  },
  {
    name: 'Melody Sauce 2',
    manufacturer: 'EVAbeat',
    description: 'Melody Sauce 2 is your new must-have music production assistant. This supercharged upgrade is a massive overhaul of the original version, with a set of powerful new features to inspire you and your music making.',
    url: 'https://www.pluginboutique.com/product/3-Studio-Tools/93-Music-Theory-Tools/8877-Melody-Sauce-2'
  },
  {
    name: 'HALO-2 Lite',
    manufacturer: 'DHPlugins',
    description: 'With a visual and creative workflow, HALO Lite combines meticulously crafted re-sampled audio with a host of synth layers, processing and FX to make huge sounding basses, leads, pads and more.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/4-Synth/10678-HALO-2-Lite'
  },
  {
    name: 'Ultra Analog Session',
    manufacturer: 'Applied Acoustics Systems',
    description: 'Few knobs, big sounds. The container may be small but Ultra Analog Session is a really fun to play and packs outstanding sounds.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/4-Synth/1980-Ultra-Analog-Session'
  },
  {
    name: 'Beatfader',
    manufacturer: 'BEATSURFING',
    description: 'Beatfader is an audio plugin designed to play beats with any DAW and MIDI-enabled controller or DJ mixer.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/7-Drum-Machine/11537-Beatfader'
  },
  {
    name: '7DeadlySnares',
    manufacturer: 'BEATSURFING',
    description: 'Unleash the power of 7DeadlySnares – the ultimate snare generator VST plugin that features 7 customizable audio engines, each inspired by one of the seven deadly sins: pride, greed, lust, envy, gluttony, wrath, and sloth.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/69-Generator/10897-7DeadlySnares'
  },
  {
    name: 'Lunchtable',
    manufacturer: 'BEATSURFING',
    description: 'LunchTable faithfully reproduces the captivating sounds of pen tapping. Producer Fki 1$t, known for his work with Post Malone, 2 Chainz, Migos and Travis Scott teamed up with BEATSURFING to create LunchTable.',
    url: 'https://www.pluginboutique.com/product/1-Instruments/64-Virtual-Instrument/10985-LunchTable'
  },
  {
    name: 'Rave Generator 3',
    manufacturer: 'Audio Blast',
    description: "RaveGenerator III VST is a versatile rompler/sampler multi-timbral plugin, ideal for crafting the nostalgic sounds of old school rave and 90's techno.",
    url: 'https://www.pluginboutique.com/products/11863'
  }
]

export const effectsData = [
  {
    name: 'BOREALIS',
    manufacturer: 'MNTRA',
    description: "BOREALIS is a dynamic reverb plugin designed for the studio, stage, and beyond. It transcends conventional reverb plugins by allowing you to create an interplay between your dry input and its wet output, using its envelope follower and unique modulation possibilities.",
    url: null
  },
  {
    name: 'BASS-FACE',
    manufacturer: 'DHPlugins',
    description: "With 3 different modes, each with increasing aggression, BASS-FACE delivers a variety of tones and craft to enhance your audio with the least amount of effort.",
    url: null
  },
  {
    name: 'Motion: Dimension',
    manufacturer: 'Excite Audio',
    description: "Motion: Dimension unveils a new realm of sonic exploration, focusing on an intricate blend of dual delays and adaptable reverbs to immerse you in a dynamic and responsive sound design journey.",
    url: null
  },
  {
    name: 'Bad Tape 2',
    manufacturer: 'Denise Audio',
    description: "Bad Tape focuses on recreating tape artefacts in a controlled way: tape stops, slight slow-downs and speed-ups, and multiple ways to go out of tune and off the grid.",
    url: null
  },
  {
    name: 'Perfect Plate XL',
    manufacturer: 'Denise Audio',
    description: "Perfect Plate XL offers refined control over the key aspects that determine a plate's sound, resulting in new possibilities to shape and combine plate reverb styles.",
    url: null
  },
  {
    name: 'Bite Harder',
    manufacturer: 'Denise Audio',
    description: "Bite Harder offers the classic bit crushing features but adds unique controls to refine or destroy your sound even more. With the Silky dial, you can smooth out the effect of the individual bit and sample controls. Alongside this, Denise added the push-pull graph, a layer of FX and a drive control.",
    url: null
  },
  {
    name: 'Finisher RETRO',
    manufacturer: 'Ujam',
    description: "Do you ever play classic records and think “That. Sounds. GREAT! How do I get that 60s (or 70/80/90s) vibe in my track?” With Finisher RETRO, it's easy.",
    url: 'https://www.pluginboutique.com/product/2-Effects/53-Multi-Effect-/7464-Finisher-RETRO'
  },
  {
    name: 'Linda IronVerb',
    manufacturer: 'Audified',
    description: "IronVerb is a classic algorithmic reverb, perfect for all reverb needs - from subtle, short reverb to huge atmospheric, ethereal, and ambient textures with long decay.",
    url: 'https://www.pluginboutique.com/product/2-Effects/17-Reverb/8542-Linda-IronVerb'
  },
  {
    name: '1A Equalizer',
    manufacturer: 'Audified',
    description: "This is familiar to everyone. Boosting and Attenuating lows or highs in a signature way is a quintessential part of the original units. They also nailed the classic Boost-and-attenuate trick!",
    url: 'https://www.pluginboutique.com/product/2-Effects/16-EQ/11258-1A-Equalizer'
  },
  {
    name: 'Pivot',
    manufacturer: 'Venomode',
    description: 'Pivot is a tilt equaliser, with adjustable slope and mid/side modes. Powerful EQ, made simple.',
    url: 'https://www.pluginboutique.com/product/2-Effects/16-EQ/5586-Pivot'
  },
  {
    name: 'Maximal 3',
    manufacturer: 'Venomode',
    description: 'Maximal 3 is a true-peak brickwall limiter, soft clipper, and broadcast standard loudness meter.',
    url: 'https://www.pluginboutique.com/product/2-Effects/9-Limiter/5583-Maximal-3'
  },
  {
    name: 'Lowtility 2',
    manufacturer: 'Venomode',
    description: 'Lowtility 2 is a low-end filter and mid/side utility plugin. It lets you cleanly cut the low frequencies from your mix, and modify the stereo width of the low end.',
    url: 'https://www.pluginboutique.com/product/3-Studio-Tools/72-Utility/5587-Lowtility-2'
  },
  {
    name: 'Complexer 2',
    manufacturer: 'Venomode',
    description: 'Complexer 2 is a multi-stage dynamics processor, featuring three independently controlled dynamics processors, each with a choice of six different modes.',
    url: 'https://www.pluginboutique.com/product/3-Studio-Tools/71-Dynamic-Processor/5585-Complexer-2'
  },
  {
    name: 'Deeq',
    manufacturer: 'Venomode',
    description: 'DeeQ is a single-band dynamic EQ filter that automatically adjusts the parameters of the EQ based on the incoming envelope or an external sidechain.',
    url: 'https://www.pluginboutique.com/product/2-Effects/19-Filter/5584-DeeQ'
  },
  {
    name: 'Electrum Core',
    manufacturer: 'United Plugins',
    description: "Electrum Core offers a straightforward attitude. United Plugins simply took Electrum's amazing amp and cab simulations and stripped them from the additional effects to give you a fast way to get straight to the desired guitar tone.",
    url: 'https://www.pluginboutique.com/product/2-Effects/18-Amp-Simulator/8449-Electrum-Core'
  },
  {
    name: 'Bassment Core',
    manufacturer: 'United Plugins',
    description: "Bassment Core can offer all that some bassists need. Just the amp and cabs. United Plugins simply took Bassment and stripped it from the additional effects to give you a fast way to get straight to the desired bass tone.",
    url: 'https://www.pluginboutique.com/product/2-Effects/53-Multi-Effect-/9254-Bassment-Core'
  },
  {
    name: 'KNOCK Clipper',
    manufacturer: 'PLUGINS THAT KNOCK',
    description: 'KNOCK Clipper is a premium quality, user adjustable hard/soft clipper designed by DECAP. It is the CLIP module from his acclaimed plugin, KNOCK.',
    url: 'https://www.pluginboutique.com/product/2-Effects/9-Limiter/9860-KNOCK-Clipper'
  },
  {
    name: 'ResDelay',
    manufacturer: 'Plugin Boutique',
    description: 'ResDelay combines two key production techniques - envelope filter and stereo delay - into one complete unit, giving you the potential to create resonant sweeps and rhythmic patterns out of any audio source.',
    url: 'https://www.pluginboutique.com/products/10918-ResDelay'
  },
  {
    name: 'ShapeMod',
    manufacturer: 'Plugin Boutique',
    description: 'ShapeMod is a volume modulation plugin that provides full flexibility to achieve modern volume ducking effects in seconds.',
    url: 'https://www.pluginboutique.com/products/10921-ShapeMod'
  },
  {
    name: 'FilterMod',
    manufacturer: 'Plugin Boutique',
    description: "Whether it's drums, bass, synths, pads or vocals, filtering is a key element of the production process for adding variation and excitement to a track. FilterMod goes beyond the traditional filter plugin to bring you 20 different filter types and two modulation envelopes, giving you complete flexibility and more creative possibilities.",
    url: 'https://www.pluginboutique.com/products/10903-FilterMod'
  },
  {
    name: 'DualClip',
    manufacturer: 'Plugin Boutique',
    description: "Clippers are an essential tool for achieving a huge range of saturation effects. They can make tracks louder by adding harmonics, they can tame peaks to keep your levels in check, or they can achieve aggressive distortion and overdrive effects. DualClip features a 16-band soft clipper and a single-band hard clipper, to achieve all these effects in one single unit.",
    url: 'https://www.pluginboutique.com/products/10920-DualClip'
  },
  {
    name: 'DriveMod',
    manufacturer: 'Plugin Boutique',
    description: "DriveMod takes the traditional distortion plugin one step further, combining heated overdrive with added life and movement. Choose from six types of distortion, shape your signal with filtering and gain, then modulate the results with custom-drawn LFO shapes.",
    url: 'https://www.pluginboutique.com/products/10895-DriveMod'
  },
  {
    name: 'PeakLimit',
    manufacturer: 'Plugin Boutique',
    description: "PeakLimit is a limiter that features 4 different limiter styles for maximizing the loudness of your track, along with an industry-standard metering display for visualising your audio and learning key information about the levels & dynamics of your track.",
    url: 'https://www.pluginboutique.com/products/10905-PeakLimit'
  },
  {
    name: 'Exceed',
    manufacturer: 'Plugin Boutique',
    description: "This smart, streamlined effect has the power to give your masters, busses or individual tracks that extra bit of polish by adding warmth, power and clarity to any audio signal.",
    url: 'https://www.pluginboutique.com/products/10894-Exceed'
  },
  {
    name: 'DriveLE',
    manufacturer: 'Plugin Boutique',
    description: "When you're striving to get that big & full sound that can be heard on commercial releases, often the key ingredient is distortion. By adding harmonics to your signal, distortion adds the presence, character, warmth, edge and cohesion that helps you achieve this sound.",
    url: 'https://www.pluginboutique.com/products/10886-DriveLE'
  },
  {
    name: 'Rum',
    manufacturer: 'Klevgrand',
    description: "Rum simulates different kinds of rooms – from acoustically treated studio booths to spaces found in an ordinary home. Think of this plug-in as if the audio source was recorded in stereo from the other side of the room, a common technique for recording acoustic instruments in order to breathe life into a dry sound source.",
    url: 'https://www.pluginboutique.com/product/2-Effects/17-Reverb/8720-Rum'
  },
  {
    name: 'Motion: Harmonic',
    manufacturer: 'Excite Audio',
    description: "Motion: Harmonic opens up new frontiers in distortion, filtering and bitcrushing, placing you at the centre of a dynamic and responsive sound-shaping experience.",
    url: 'https://www.pluginboutique.com/product/2-Effects/30-Distortion/10435-Motion-Harmonic'
  },
  {
    name: 'Lifeline Expanse',
    manufacturer: 'Excite Audio',
    description: "Lifeline Expanse creates authenticity and realism by using intricate re-amping techniques. Re-amping is the process of taking an audio signal and playing it out in an environment. The signal is sent through amps, mics and effects to add colour, texture and depth, then re-recorded.",
    url: 'https://www.pluginboutique.com/product/2-Effects/53-Multi-Effect-/8036-Lifeline-Expanse'
  },
  {
    name: 'Lifeline Console',
    manufacturer: 'Excite Audio',
    description: "While Lifeline Expanse simulates the space and characteristics of real recording environments and re-amping methods, Lifeline Console focuses on mixing in a control room, offering you the sound of sought-after hardware and analogue formats all within your DAW.",
    url: 'https://www.pluginboutique.com/product/2-Effects/21-Channel-Strip/9066-Lifeline-Console'
  },
  {
    name: 'Distiller',
    manufacturer: 'Diginoiz',
    description: "From removing unwanted frequencies to adding rich harmonics, from classic saturation, to experimental wavetable distortion, from lo fi textures creation to contemporary bass sound design - Distiller does it all and much more.",
    url: 'https://www.pluginboutique.com/product/2-Effects/53-Multi-Effect-/10007-Distiller'
  },
  {
    name: 'Choric',
    manufacturer: 'Caelum Audio',
    description: "Produce thick, rich chorus textures with up to 32 voices, and explore traditional chorus sounds and natural realistic unisons.",
    url: 'https://www.pluginboutique.com/product/2-Effects/11-Chorus/10342-Choric'
  },
  {
    name: 'Comeback Kid',
    manufacturer: 'Baby Audio',
    description: "Of all the essential mix tools, the delay may be the most creatively satisfying. So Baby Audio asked themselves: could they take one of their favourite effects and give it an upgrade?",
    url: 'https://www.pluginboutique.com/product/2-Effects/10-Delay/6204-Comeback-Kid'
  },
  {
    name: 'Parallel Aggressor',
    manufacturer: 'Baby Audio',
    description: "Parallel processing plays a fundamental part in getting a “big” mix sound. By processing multiple copies of the same track and blending them together, you can add more punch to a mix — in a more natural-sounding way.",
    url: 'https://www.pluginboutique.com/product/2-Effects/8-Compressor/6639-Parallel-Aggressor'
  },
  {
    name: 'Super VHS',
    manufacturer: 'Baby Audio',
    description: "As kids of the 80s, the Baby Audio team have always been drawn to the sound of slightly out-of-tune synths, warm tape saturation, gritty samples and grainy reverbs. So they set out on a mission to capture the lofi spirit of our favorite decade.",
    url: 'https://www.pluginboutique.com/product/2-Effects/53-Multi-Effect-/5900-Super-VHS'
  },
  {
    name: 'InstaLooper 3',
    manufacturer: 'Audio Blast',
    description: "This glitch effect tool can loop the real time audio to apply time and pitch changes to create effects on your musical creations. Use this looper plugin to create breaks at the end of measures and unstructure the sound, looping it fast to create roll effects. InstaLooper3 is not a traditional loop recorder.",
    url: 'https://www.pluginboutique.com/product/2-Effects/53-Multi-Effect-/10479-InstaLooper-3'
  },
  {
    name: 'Breadslicer Pro',
    manufacturer: 'Audio Blast',
    description: "The BreadSlicer Pro is a creative glitch effect tool. Just press the “ACTIVE” button and the audio input will be cut into slices and will be transformed into breaks, rolls and glitches instantly.",
    url: 'https://www.pluginboutique.com/product/2-Effects/53-Multi-Effect-/10875-BreadSlicer-Pro'
  },
  {
    name: 'Blast Delay',
    manufacturer: 'Audio Blast',
    description: "A simple and intuitive delay with a built-in parameter sequencer. You can program independent loops of motion sequences for each knob with independent speeds and tweak your effect in live situations and recall all the parameters in the sequencer.",
    url: 'https://www.pluginboutique.com/product/2-Effects/10-Delay/10478-Blast-Delay'
  },
  {
    name: 'Equalize',
    manufacturer: 'Acon Digital',
    description: "Acon Digital Equalize is a parametric equalizer plug-in that combines unique features with excellent workflow and an intuitive user interface. Unlike other equalizers, you can freely adjust not only center frequency, gain and bandwidths, but also the filter slope for each band.",
    url: 'https://www.pluginboutique.com/product/2-Effects/16-EQ/1287-Equalize'
  },
  {
    name: 'Obsidian',
    manufacturer: 'W.A. Production',
    description: "Route any audio through OBSIDIAN and it will transform the nature and timbre of the sound in sometimes unexpected ways, adding colour with a secret-sauce FX matrix and spectral delay.",
    url: 'https://www.pluginboutique.com/product/2-Effects/53-Multi-Effect-/10475-Obsidian'
  },
  {
    name: 'MorphVerb',
    manufacturer: 'United Plugins',
    description: "MorphVerb covers all reverb types you could think of. It offers lush algorithms for Spring, Ambience, Echoes, Room, Chamber or Plate. It also features great spaces like Hall or Cathedral and your reverb can even be Infinite.",
    url: 'https://www.pluginboutique.com/product/2-Effects/17-Reverb/6366-MorphVerb'
  },
  {
    name: 'FireCharger',
    manufacturer: 'United Plugins',
    description: "FireCharger is a universal processor with an intelligent algorithm for spectral charging and instant bettering of the sound, it also includes the emulation of an analogue tape and advanced exciters.",
    url: 'https://www.pluginboutique.com/product/2-Effects/53-Multi-Effect-/7050-FireCharger'
  },
  {
    name: 'Retronaut',
    manufacturer: 'United Plugins',
    description: "Retronaut is a multi-voice vibrato and chorus with LoFi effects. It is inspired by old analogue gear such as bucket brigade chips, tape cassettes, vinyl records and voltage-controlled filters.",
    url: 'https://www.pluginboutique.com/product/2-Effects/53-Multi-Effect-/10206-Retronaut'
  },
  {
    name: 'StereoSavage 2',
    manufacturer: 'Plugin Boutique',
    description: "Achieve the stunning stereo your mix deserves. StereoSavage provides an innovative stereo toolbox, combining tried and tested studio techniques with emulations of modern and classic tools that will bring your mix to life.",
    url: 'https://www.pluginboutique.com/product/2-Effects/27-Stereo-Width/2195-StereoSavage-2'
  },
  {
    name: 'Cloudmax',
    manufacturer: 'Karanyi Sounds',
    description: "Experience a world of sonic textures with the dual granular engine of the Cloudmax Texture Resonator. Equipped with multiple effect modules that can turn any sound source into a soft, lush soundscape or ethereal sound effect in no time.",
    url: 'https://www.pluginboutique.com/product/2-Effects/53-Multi-Effect-/10926-Cloudmax'
  },
  {
    name: 'RX 1200',
    manufacturer: 'Inphonik',
    description: "Meet the RX1200, Inphonik's 12-bit sampler instrument based on the E-mu SP-1200, a landmark sampler designed by Dave Rossum and released by E-mu Systems in 1987.",
    url: 'https://www.pluginboutique.com/product/1-Instruments/5-Sampler/10789-RX-1200'
  },
  {
    name: 'RX950',
    manufacturer: 'Inphonik',
    description: "Meet the RX950 Classic AD/DA Converter, Inphonik's first effect plug-in, designed to perfectly mimic the whole AD/DA conversion process of the Akai S950 in order to give your music this vintage, warm and crunchy sound with the ease & flexibility of a VST.",
    url: 'https://www.pluginboutique.com/product/3-Studio-Tools/72-Utility/5631-RX950-Classic-AD-DA-Converter'
  },
  {
    name: 'Dynamic Eq',
    manufacturer: 'Initial Audio',
    description: "Dynamic Eq can be used as a standard equaliser for your day to day needs, when you need more control, the transparent compression algorithm can be used to tame or boost specific frequencies.",
    url: 'https://www.pluginboutique.com/product/2-Effects/16-EQ/8827-Dynamic-Eq'
  },
  {
    name: 'Flux Pro',
    manufacturer: 'Caelum Audio',
    description: "Flux Pro is a multi-fx modulation plugin that transforms your sounds into colourful, characterful and dynamic tracks.",
    url: 'https://www.pluginboutique.com/product/2-Effects/33-Modulator/9051-Flux-Pro'
  },
  {
    name: 'Tape Pro',
    manufacturer: 'Caelum Audio',
    description: "Tape Pro is a multi-effect tape audio plugin that introduces warmth, frequency shaping, modulation, noise and delay.",
    url: 'https://www.pluginboutique.com/product/2-Effects/96-Tape-Emulation/7703-Tape-Pro'
  },
  {
    name: 'BLEASS Reverb',
    manufacturer: 'BLEASS',
    description: "BLEASS Reverb is a unique audio unit effect with filters, freeze toggle button, frequency shifter/waveshaper X/Y panel, and side-chained compressor for duck effect!",
    url: 'https://www.pluginboutique.com/product/2-Effects/17-Reverb/7532-BLEASS-Reverb'
  },
  {
    name: 'BLEASS Motion EQ',
    manufacturer: 'BLEASS',
    description: "BLEASS Motion EQ is a powerful yet easy-to-use audio equalizer with unique features that unlock new realms of tonal creativity.",
    url: 'https://www.pluginboutique.com/product/2-Effects/16-EQ/9299-BLEASS-Motion-EQ'
  },
  {
    name: 'BLEASS Granulizer',
    manufacturer: 'BLEASS',
    description: "BLEASS Granulizer is a granular texture effect plugin with randomization, 4 multi-bus audio inputs and a mesmerizing 3D visualizer. BLEASS Granulizer generates short samples (‘grains’) from different input signals by modulating different parameters which you can visualize.",
    url: 'https://www.pluginboutique.com/product/2-Effects/17-Reverb/7767-BLEASS-Granulizer'
  },
  {
    name: 'MOD-EQ-1',
    manufacturer: 'DHPlugins',
    description: "The MOD-EQ-1 is a creative modulation EQ. Primarily a 5 band parametric EQ, each control can be modulated with one of 2 LFOs or an Envelope Follower to create interesting modulations and EQ morphing, even reacting to the audio signal input.",
    url: 'https://www.pluginboutique.com/product/2-Effects/16-EQ/11611-MOD-EQ-1'
  },
  {
    name: 'MiniLooper',
    manufacturer: 'Audio Blast',
    description: "Use MiniLooper to create breaks, fills, rolls, reverse, and glitch effects synchronized to the tempo of the DAW. Unstructure the sound, get interesting glitch effects, rolls, fills, and more, easily!",
    url: 'https://www.pluginboutique.com/product/2-Effects/13-Drum-FX/11464-MiniLooper'
  },
  {
    name: 'Outlaw',
    manufacturer: 'W.A. Production',
    description: "Whether you're mixing live sound, smoothing vocals or balancing solo instruments, Outlaw is the gain-riding plugin you've been looking for. Outlaw listens to the incoming audio and compensates for volume changes, mimicking the mixing desk fader movement of a real-life audio engineer.",
    url: 'https://www.pluginboutique.com/product/3-Studio-Tools/71-Dynamic-Processor/5059-Outlaw'
  },
  {
    name: 'Vocal Splitter',
    manufacturer: 'W.A. Production',
    description: "For decades, audio engineers have used a certain processing trick on vocals to help lacklustre mono vocals leap from stereo speakers. Now you can replicate that secret technique with one simple plugin. Vocal Splitter is a handy one-stop unit for turning mono vocals into thick, modern-sounding stereo.",
    url: 'https://www.pluginboutique.com/product/2-Effects/54-Vocal-Processing/5817-Vocal-Splitter'
  },
  {
    name: 'Puncher 2',
    manufacturer: 'W.A. Production',
    description: "In terms of compression, this is the all in one tool you've been dreaming of. Packed into its compact design you will find 3 different effects. A Transient Shaper, Multiband Compressor and Parallel Compressor.",
    url: 'https://www.pluginboutique.com/product/2-Effects/8-Compressor/4589-Puncher-2'
  }
]

export const utilitiesData = [
  {
    name: 'BASSROOM',
    manufacturer: 'Mastering The Mix',
    description: "Balancing the low-end is one of the hardest challenges in any mix or master. BASSROOM is a final mix and mastering EQ that helps beginners and pros nail their low-end in seconds.",
    url: null
  },
  {
    name: 'EXPOSE 2',
    manufacturer: 'Mastering The Mix',
    description: "So many tracks get distributed with a poor EQ balance, true peak clipping, over-compression, phase issues, and more. Mastering the Mix wants you to catch these issues before it's too late. With EXPOSE 2, you can deliver the best listening experience and release your music with confidence.",
    url: null
  },
  {
    name: 'Scaler 2',
    manufacturer: 'Plugin Boutique',
    description: "Scaler 2 is an inspirational and powerful music theory workstation that gives you access to a world of new ideas, expressions and melodies.",
    url: 'https://www.pluginboutique.com/product/3-Studio-Tools/93-Music-Theory-Tools/6439-Scaler-2'
  },
  {
    name: 'REFERENCE 2',
    manufacturer: 'Mastering The Mix',
    description: "Reference 2 is packed with powerful tools and never seen before insights to help you get closer than ever to the sound of your favourite music.",
    url: 'https://www.pluginboutique.com/product/3-Studio-Tools/95-Referencing-Tools/3675-REFERENCE-2'
  },
  {
    name: 'RESO',
    manufacturer: 'Mastering The Mix',
    description: "RESO is a dynamic resonance suppressor that helps you quickly identify and resolve resonances in your audio.",
    url: 'https://www.pluginboutique.com/product/3-Studio-Tools/71-Dynamic-Processor/8320-RESO'
  },
  {
    name: 'FUSER',
    manufacturer: 'Mastering The Mix',
    description: "FUSER is the ultimate solution for creating sonic clarity in messy mixes. FUSER resolves conflicting channels by assisting you in adjusting volume balance, enhancing phase optimisation, and implementing intelligent mid-side side chain ducking.",
    url: 'https://www.pluginboutique.com/product/3-Studio-Tools/71-Dynamic-Processor/11102-FUSER'
  },
  {
    name: 'MIXROOM',
    manufacturer: 'Mastering The Mix',
    description: "MIXROOM is an intelligent EQ that guides you to set the perfect balance of richness and clarity whether you're working on a vocal, a synth or mastering your music.",
    url: 'https://www.pluginboutique.com/product/2-Effects/16-EQ/6349-MIXROOM'
  },
  {
    name: 'LIMITER',
    manufacturer: 'Mastering The Mix',
    description: "LIMITER is a great-sounding, smart mastering plugin that analyses your audio and suggests the best settings for your music. Avoid the hassle and create incredible sounding masters in less time with LIMITER.",
    url: 'https://www.pluginboutique.com/product/3-Studio-Tools/71-Dynamic-Processor/9997-LIMITER'
  },
  {
    name: 'SEQUND',
    manufacturer: '510k',
    description: "SEQUND is a powerful polyrhythmic sequencer combining, and improving, all the best features from the already successful Max4Live sequencers created by electronic musician Alexkid, redesigned in collaboration with Tadashi Tsuginomori from HY-Plugins.",
    url: 'https://www.pluginboutique.com/product/2-Effects/24-Sequencer/9931-SEQUND'
  },
  {
    name: 'Groove Shaper',
    manufacturer: 'Pitch Innovations',
    description: "Groove Shaper: Your one-of-a-kind creative sequencer for instant, inspired rhythms. Effortlessly craft hi-hats, bass lines, short string motifs and more with just a single note.",
    url: 'https://www.pluginboutique.com/product/2-Effects/53-Multi-Effect-/11204-Groove-Shaper-'
  },
  {
    name: 'KSHMR Chain',
    manufacturer: 'Excite Audio',
    description: "KSHMR Chain is an innovative utility that instantly copies your plugin chain from one track to countless others, allowing you to spend your time and effort where it matters most while producing. Say goodbye to Copy & Paste.",
    url: 'https://www.pluginboutique.com/product/3-Studio-Tools/72-Utility/9641-KSHMR-Chain'
  },
  {
    name: 'VISION 4X',
    manufacturer: 'Excite Audio',
    description: "Developed in partnership with electronic music trio NOISIA, VISION 4X gives you the insights you need to prepare your tracks ready for competition with today’s top mixes, and the clarity to make the right mixing decisions with confidence – however modest your setup.",
    url: 'https://www.pluginboutique.com/product/2-Effects/25-Spectral-Analysis/9301-VISION-4X'
  },
  {
    name: 'MixChecker',
    manufacturer: 'Audified',
    description: "This ultimate mixing assistant will help you to get better mixes instantly. MixChecker saves your precious time. Plug it in the master track of your project as the last segment of your listening chain and quickly turn your studio monitors into classic reference monitors or several consumer devices.",
    url: null
  }
]

export const dawData = [
  {
    name: 'Bitwig Studio 8-Track',
    manufacturer: 'Bitwig',
    description: "Bitwig Studio 8-Track is a great way to produce, record, and perform like a pro. As with every Bitwig product, 8-Track was designed around a lighting-fast workflow and a substantial library of devices and sounds. Connect your controller, record yourself, produce an arrangement, design new sounds, or just jam.",
    url: 'https://www.bitwig.com/8-track/'
  },
  {
    name: 'Cubase LE',
    manufacturer: 'Steinberg',
    description: "Cubase LE is a special, compact version which uses the same core technologies as Cubase Pro. It provides all the basic tools for recording, editing and mixing - from the initial idea to the final masterpiece.",
    url: 'https://www.steinberg.net/cubase/le/'
  }
]

export const soundsData = [
  {
    name: 'Loopcloud Sounds',
    manufacturer: 'Loopcloud',
    description: "The Loopcloud Sounds plugin gives you instant access to half a million royalty-free sounds. The advanced search and our seamless “Similar Sounds” features help you to instantly find the sounds your track needs, without leaving your DAW.",
    url: 'https://www.loopcloud.com/cloud/blog/5124-Loopcloud-Sounds-Your-new-go-to-plugin-for-sounds'
  }
]
